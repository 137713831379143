<template>
  <div>
    <div class="title fs28 fc666">登记内容</div>
    <div class="inner help">
      <van-field
        class="h100"
        v-model.trim="message"
        rows="23"
        type="textarea"
        placeholder="请填写你能为案主提供的帮助内容"
      />
    </div>
    <div @click="helpSubmit" class="help-buttom-b dfc flex-center">
      <icy-button height="0.8rem" border-radius="0.44rem"
        >登记我的帮助内容</icy-button
      >
      <icy-iphone></icy-iphone>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      id: "",
      routerType: 0
    };
  },
  created() {
    this.$title("登记帮助内容");
    this.id = parseInt(this.$route.query.id) || "";
    this.routerType = parseInt(this.$route.query.type) || 0;
  },
  mounted() {},
  methods: {
    async helpSubmit() {
      if (!this.message) {
        this.$toast("请填写你能为案主提供的帮助内容");
        return;
      }
      if (!this.id) {
        this.$toast("未找到对应会议");
        this.$goReplace("home");
        return;
      }
      const data = {
        id: this.id,
        content: this.message
      };
      const resp = await this.$API
        .post("addHelpContent", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("添加成功");
        this.$goReplace("meetingDetail", {
          active: 3,
          id: this.id,
          type: this.routerType
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.title {
  padding: 0.36rem;
  height: 1rem;
}
.inner {
  width: 6.78rem;
  margin: 0 auto;
  min-height: calc(100vh - 1rem - 1.2rem);
}
.help-buttom-b {
  width: 7.5rem;
  margin: 0 auto;
  padding: 0.2rem 0;
  height: 1.2rem;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.2rem - 15px);
  }
  .help-buttom-b {
    height: calc(1.2rem + 15px);
  }
}

// iphone Xs Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.2rem - 15px);
  }
  .help-buttom-b {
    height: calc(1.2rem + 15px);
  }
}

// iphone XR
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .inner {
    min-height: calc(100vh - 1rem - 1.2rem - 15px);
  }
  .help-buttom-b {
    height: calc(1.2rem + 15px);
  }
}
</style>
